import React from 'react';
import { graphql } from 'gatsby';
import { AllTagsPage } from 'mcc-cms';

const LayoutPage = ({data}) => {
  return (
    <AllTagsPage
      data={data}
      padding="9rem 1.5rem 3rem 1.5rem"
    />
  )
}
export default LayoutPage

export const tagPageQuery = graphql`
  query TagsPageTemplateByID($id: String!, $langKey: String!) {
    site {
      siteMetadata {
        title
      }
    }
    pageDef: markdownRemark(
      fields: { langKey: { eq: $langKey } }
      frontmatter: {templateKey: {eq: "all-tags-page"}}, id: {eq: $id}
    ) {
      fields {
        langKey
        slug
      }
      frontmatter {
        title
      }
    }
    tagList: markdownRemark(
      frontmatter: { key: { eq: "tags-list" } }
    ) {
      frontmatter {
        defaultLangKey
        tagList {
          tag
          featured
          topLevel
          overrideDefaultLangKey
          translations {
            langKey
            tagDisplay
            shortDesc
            longDesc
          }
        }
      }
    }
    groupTags: allMarkdownRemark(
      limit: 1000,
      filter: {fields: {langKey: {eq: $langKey}}}
    ) {
      group(field: fields___tags) {
        fieldValue
        totalCount
      }
    }
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___createDate], order: DESC }
      filter: { fields: {langKey: {eq: $langKey}}, frontmatter: {templateKey: {eq: "standard-content"}, tags: {elemMatch: {value: {eq: null}}} } }
    ) {
      totalCount
      edges {
        node {
          fields {
            pathSlug
            langKey
          }
          frontmatter {
            title
            description
            createDate
            updateDate
            featuredPost
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags {
              value
              label
            }
          }
        }
      }
    }
  }
`
